<template>

  <div>
    <div class="header">
      <div class="header-c">
        <div class="header-top">
					<span class="header-left">
						<span style="float:left">{{ datetime }}</span>
						<span style="float:left;" id="today_container">
							<iframe class="iframe-banner" width="350" height="20" style="margin-left: 10px;"
                      allowtransparency="true"
                      src="https://i.tianqi.com/index.php?c=code&amp;id=11&amp;color=%23FFF&amp;icon=1&amp;site=15">
							</iframe>
						</span>
					</span>

          <div class="header-right">
            <a href="http://www.ccia.org.cn/" target="_blank">
							<span
                  style="padding-right:5px;border-right:1px solid #fff;font-size:16px;line-height:21px;">中国通信工业协会</span>
            </a>
            <p id="mnb2">
              <img src="@/assets/img/mail.png" alt="">
              <a target="_blank" href="http://mail.cciaimm.org.cn">邮箱</a>
            </p>
            <p id="mnb3">
              <img src="@/assets/img/tal2.png" alt="">
              <a target="_blank" @click="goto">联系我们</a>
            </p>
          </div>
        </div>
        <div class="header-cen">
          <a href="/">
            <img style="display:block;height:60px;border:none;" src="@/assets/img/logo3.png" class="logo"
                 alt="">
          </a>
          <div class="top_rbr">
            <form name="searchform" target="_blank">
              <input class="search_txt" type="text" autocomplete="off" v-model="searchvalue"
                     placeholder="请输入关键词" clearable>
              <input class="search_tj" type="button" value="搜索" @click="searchClick">
            </form>
          </div>
          <div class="nav-sg">
          </div>
          <div class="nav-sg233">
          </div>
        </div>
      </div>
    </div>
    <div class="nav">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router
               background-color="#0147c1" active-text-color="#fff">
        <el-menu-item index="/pc">首页</el-menu-item><!-- 点击图片跳转到首页 -->
        <el-menu-item index="/introduction">机构简介</el-menu-item>
        <el-menu-item index="/information">行业资讯</el-menu-item>
         <el-menu-item index="/standard">行业规范</el-menu-item>
        <el-menu-item index="/observation">行业观察</el-menu-item>
        <el-menu-item index="/case">行业案例</el-menu-item>
        <el-menu-item index="/works">活动主办</el-menu-item>
        <el-menu-item index="/connection">联系我们</el-menu-item>
        <div class="clear"></div>
      </el-menu>
      <div class="clear"></div>
    </div>
  </div>

</template>


<script>
export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      input: '',
      datetime: '',
      searchvalue: "",
      searchdata: {}
    };
  },
  methods: {
    handleSelect() {
      /* console.log(key, keyPath); */
    },
	goto(){
		this.$router.push("/connection")
	},
    searchClick() {
		this.$router.push({path:'/search',query:{searchvalue: this.searchvalue}})
      /* this.$post("/industry/searchboxinfo", {
        title: this.searchvalue,
        current: 1,
        size: 5
      }).then(resp => {
        this.searchdata = resp.data
        if (this.searchdata === null) {
          this.$message({
            message: resp.msg,
            type: 'false'
          })
        } else {
          this.$router.push({
            path: '/search',
            query: {
              searchdata: this.searchdata,
              searchvalue: this.searchvalue
            }
          })
        }
      }) */
    }
  },
  created() {
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth() + 1;
    let dd = new Date().getDate();
    let wk = new Date().getDay();
    let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    let week = weeks[wk]
    this.datetime = "今天是 " + yy + "年" + mm + "月" + dd + "日" + week
  }
}
</script>

<style>
@import '../../assets/css/header.css';

.nav ul[data-v-2f029f00] {
  margin: 0 auto;
  width: 1200px;
  height: 49.5px;
  background-color: #0147c1;
}

.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border-bottom: 2px solid transparent;
  font-family: 微软雅黑, serif;
  display: block;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}


.el-carousel__container{
  height: 422px;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  color: #fff;
  background-color:rgb(2,101,205);
}

/deep/ .el-menu-item:hover {
  background-color:rgb(2,101,205);
}

.search_txt::placeholder{
  color:#98c5f5;
}
</style>
